import React from 'react'
import './Playzoneau.css'; 
import nlFlg from '../images/nlFlg.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/nlFlg.png'

function Guidewinnl() {
  return (
    <div>
    <Helmet>
        <title>Reisbureau in Nederland</title>
        <meta name="description" content="Reisbureau in Nederland" />
        <link rel="icon" type="image/x-icon" href={favicon} />
    </Helmet>
    <nav class="navbar navbar-expand-lg sticky-top bg-white">
        <div class="container">
            <a class="navbar-brand" href="#"><img src={nlFlg} class="top-logo" alt="logo.com"/> <span>Reisbureau in Nederland</span> </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigatie">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#home">Startpagina</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#list">Lijst</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nohover" href="#contact-us">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                    <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Bezoek de beste casinohotels van Nederland</h3>
                    <p>Droomt u van een uitje dat woonkamerluxe combineert met casino-opwinding? Zoek niet verder - Nederland biedt een selectie van eersteklas casinohotels die een onvergetelijke reis beloven. Of u nu op zoek bent naar een exclusief dakterras of een luxe suite met adembenemend uitzicht, deze zorgvuldig geselecteerde bestemmingen bieden de perfecte combinatie van luxe, entertainment en de kans om uw geluk te beproeven aan de speeltafels.</p>
                </div>
            </div>
        </div>
    </section>

    <section id="list" class="hotel section-padding padding-top-80">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-howtouse text-left pb-5">
                        <div class="container-body">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back2} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Ville Resort & Casino</a></h5>
                                            <p class="card-text">Voor degenen die zowel de rust als de opwinding van het gamen waarderen, biedt The Ville Resort & Casino de perfecte balans. Ontspan in minimalistische maar stijlvolle kamers, maak uw hoofd leeg voordat u de wereld van gokken betreedt. Van 9 tot 5 beleeft u de spanning van 370 gokautomaten en meer dan 20 tafelspelen, wat eindeloos entertainment biedt tijdens uw verblijf. Maar The Ville Resort is meer dan een paradijs voor gokkers - het is een oase van romantische sereniteit.</p>
                                            <a href="#" class="list-btn">Boek nu</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Zeer goed</h4>
                                                    <p>100% geverifieerde beoordelingen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back4} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Pullman Reef Hotel Casino</a></h5>
                                            <p class="card-text">Ervaar pure verfijning in het Pullman Reef Hotel Casino. Stel je voor dat je ontspant in een zwembad op het dak, omringd door wuivende palmen, terwijl je geniet van het adembenemende uitzicht op het rif. De accommodaties gaan verder dan het gewone, met kamers met bubbelbaden en privébalkons met panoramisch uitzicht.</p>
                                            <a href="#" class="list-btn">Boek nu</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Zeer goed</h4>
                                                    <p>100% geverifieerde beoordelingen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back3} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Star Gold Coast</a></h5>
                                            <p class="card-text">Bent u op zoek naar een koninklijk toevluchtsoord? The Star Gold Coast is het antwoord. Geniet van luxe suites die weelde herdefiniëren. Elke kamer is een toevluchtsoord van verfijnd comfort. Ontdek culinaire hoogstandjes in de acht restaurants en bars, en proef heerlijke creaties die uw smaakpapillen prikkelen.</p>
                                            <a href="#" class="list-btn">Boek nu</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Zeer goed</h4>
                                                    <p>100% geverifieerde beoordelingen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col mb-4">
                                    <div class="card list-hover">
                                        <img src={back5} class="card-img-top" alt="..."/>
                                        <div class="card-body">
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star checked-star"></span>
                                            <span class="fa fa-star"></span>
                                            <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Crown Melbourne</a></h5>
                                            <p class="card-text">Crown Melbourne verwelkomt u met een verscheidenheid aan keuzes, of u nu op zoek bent naar stadsgezichten of rivierpanorama's. Geniet van luxe in magnifieke villa's met grote ramen die een adembenemend uitzicht vastleggen. Als u op zoek bent naar betaalbare luxe zonder in te boeten op stijl, zult u verrukt zijn van de doordacht ontworpen kamers.</p>
                                            <a href="#" class="list-btn">Boek nu</a>
                                        </div>
                                        <div class="card-body">
                                            <div class="booking-rating">
                                                <div class="verified-point">8.6</div>
                                                <div class="rating-info">
                                                    <h4>Zeer goed</h4>
                                                    <p>100% geverifieerde beoordelingen</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contact-us" class="hotel section-padding bg-light">
        <div class="container">
            <div class="row align-items-center">
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11984.056463345145!2d4.916102077149423!3d52.391162236177465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1snl!2snl!4v1731670575718!5m2!1snl!2snl" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-light p-2 text-center">
        <div class="container padding-20">
            <div class="social-icon">
                <a href="#" class="fa fa-facebook"></a>
                <a href="#" class="fa fa-instagram"></a>
            </div>
            <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Privacybeleid</a>
            <p class="text-dark footer-text">© 2023 Copyright GuideWinNl
.com Alle rechten voorbehouden.</p>
        </div>
    </footer>
</div>

  )
}

export default Guidewinnl

